const projectsData = [
    {
        id: 1,
        title: "This insta does not exist",
        date: "Juin 2022",
        techno: ["React", "React-redux", "Sass", "API"],
        infos: "Clone du réseau social Instagram par META. La quasi totalité du site a été produit avec l'aide d'API de banque d'images et Random User API. Ai-je réussi à reproduire ce réseau social au pixel pret ?",
        img: "./assets/img/notInstagram.png",
        link: "https://this-insta-does-not-exist.netlify.app/",
    },
    {
        id: 2,
        title: "ClonePage | Starbucks",
        date: "Octobre 2022",
        techno: ["Javascript", "Sass"],
        infos: "Pour ceux désireux de voir à quoi ressemblait la homepage de Starbucks pour la saison automne 2022. Clone de la Homepage en Vanilla Javascript et Sass",
        img: "./assets/img/homepageStarbucks.PNG",
        link: "https://peallyz.github.io/Starbucks-Homepage/",
    },
    {
        id: 3,
        title: "Le blog du developpeur",
        date: "Aout 2022",
        techno: ["React", "Firebase", "Sass"],
        infos: "Blog (CRUD) utilisant Firebase, la base de données de google. Vous avez la possibilité de créer un compte et, à votre tour, poster des articles ou réagir aux articles postés par d'autres utilisateurs. Créez, Lisez, Modifiez, Supprimez.",
        img: "./assets/img/devBlog.png",
        link: "https://peallyz.github.io/dev-blog/",
    },
    {
        id: 4,
        title: "Cooking App",
        date: "Avril 2020",
        techno: ["React", "API", "Sass"],
        infos: "Retrouvez toutes les recettes fournit par l'API TheMealDB. Effectuez une recherche en fonction de l'origine du plat ou bien de son nom, ayez un aperçu des ingrédients ou allez plus loin pour obtenir toutes les informations nécessaires à la préparation.",
        img: "./assets/img/CookingApp.PNG",
        link: "https://react-cooking-app.netlify.app/",
    },
    {
        id: 5,
        title: "ShiFuMi",
        date: "Juin 2022",
        techno: ["React", "Sass"],
        infos: "Jouez au ShiFuMi seul contre le site ou bien contre un ami en local. Le ShiFuMi est trop simple pour vous ? Trois choix sont trop peu pour votre intellect, n'hésitez pas a mettre le jeu en difficile (Idée de projet par FrontEnd Mentor)",
        img: "./assets/img/Shifumi.png",
        link: "https://peallyz.github.io/ShiFuMi/",
    },
    {
        id: 6,
        title: "Sneakers",
        date: "Juin 2020",
        techno: ["JavaScript", "Sass"],
        infos: "Vous recherchez une nouvelle paire de Sneakers pour votre collection ? Ajoutez à votre panier cette unique paire de chaussures sur ce nouveau E-Commerce. (Idée de projet par FrontEnd Mentor)",
        img: "./assets/img/sneakersShop.png",
        link: "https://peallyz.github.io/FrontEnd--Ecommerce-product-page-main/",
    },
];

export default projectsData;
