import React from "react";

const Footer = () => {
    return (
        <footer>
            <h5>&copy; Copyright 2022 - Pierre ANDRE. Tous droits réservés.</h5>
        </footer>
    );
};

export default Footer;
