import React from "react";
import DynamicText from "./DynamicText";

const Job = () => {
    return (
        <h2>
            Developpeur FrontEnd <DynamicText />
        </h2>
    );
};

export default Job;
